<template>
  <div class="experience">
    <ComHeader></ComHeader>
    
    <div class="banners">
      <img src="../assets/imgs/Ontrial.jpg" alt="">
    </div>
     <div class="navtion">
      <div class="aboution">
        <!-- 表单 -->
        <div class="forms">
          <el-form :inline="true" :model="form" class="demo-form-inline">
            <el-form-item label="怎么称呼您">
              <el-input v-model="form.name" placeholder="怎么称呼您"></el-input>
            </el-form-item>
            <el-form-item label="您的联系方式">
              <el-input v-model="form.tel" placeholder="您的联系方式"></el-input>
            </el-form-item>
            <el-form-item label="您的公司全称">
              <el-input v-model="form.company" placeholder="您的公司全称"></el-input>
            </el-form-item>
          </el-form>
          <!-- 意见栏 -->
          <div>
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="你的想法">
                <el-input type="textarea" v-model="form.content"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :class="{nosub: !isRule}" @click="onSubmit">提交</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="text">
            <p>我们会在24小时之内联系您开通体验账号，还请您耐心等待</p>
          </div>
        </div>
      </div>
    </div>
     <ComFooter></ComFooter>
  </div>
</template>

<script>
import api from '@/api/api'
import ComFooter from "../components/Footer";
import ComHeader from "../components/Header";
export default {
  name: "experience",
  components: {
    ComFooter,
    ComHeader
  },
   data() {
    return {
      formInline: {
        user: "",
        region: ""
      },
      form: {
        type: 1,
        name: '',
        tel: '',
        company: '',
        content: ''
      }
    };
  },
  computed: {
    isRule() {
      return this.form.name && this.form.tel && this.form.company && this.form.content
    }
  },
  methods: {
    onSubmit() {
      if (this.isRule) {
        api.post("cheduiweb/public/index.php/api/feedback/add", this.form).then(res => {
          if (res.data.status === 200) {
            alert("提交成功");
          } else {
            alert("提交失败请重试");
          }
        });
      } else {
        alert("请完善");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.banners{
  
    margin-bottom: -4px!important;
  
}
.navtion {
  background-color: #181d29;
  height: 100%;
  width: 100%;
  .aboution {
    width: 1267px;
    height: 450px;
    background-color: #fff;
    margin-left: 326px;
    .forms {
      width: 950px;
      margin: 0 0 0 150px;
      .el-form {
        padding-top: 30px;
        .el-button--primary {
          color: #fff;
          background-color: #ff7200;
          border-color: #ff7200;
          &.nosub {
            background-color: #ccc;
            border-color: #ccc;
          }
        }
        
      }
      .text{
        color: #a8a8a8;
        margin-left:80px;
      }
    }
  }
}
</style>