<template>
  <div class="foters">
    <div class="foter-up">
      <!-- 首页相关 -->
      <div class="foter-foo">
        <!-- 页脚内容 -->
        <div class="link">
          <el-row class="link-het" :gutter="20">
            <el-col :span="16">
              <div class="grid-content bg-purple">
                <el-row class="grid" :gutter="20">
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <span>首页</span>
                    </div>
                    <p>合作共赢</p>
                    <p>区域代理</p>
                  </el-col>

                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <span>解决方案</span>
                    </div>
                    <p>智慧牧场</p>
                    <p>银行监控</p>
                    <p>政府监督</p>
                    <p>保险理财</p>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <span>商务合作</span>
                    </div>
                    <p>合作共赢</p>
                    <p>区域代理</p>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <span>新闻动态</span>
                    </div>
                    <p>银行动态</p>
                    <p>企业动态</p>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <span>关于我们</span>
                    </div>
                    <p>公司介绍</p>
                    <p>团队介绍</p>
                    <p>联系我们</p>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <div class="tel-box">
              <el-col class="tel" :span="8">
                <div class="grid-content bg-purple">
                  <span style="color:#fff;font-size:34px;margin-left: 20px;">028-69730925</span>
                </div>
                <div class="text-p">
                  <p>企业邮箱：zd@jixinghai.com</p>
                  <p>企业地址：四川省成都市高新区环球中心N2-609号 610093</p>
                </div>
              </el-col>
            </div>
          </el-row>
        </div>
        <div class="link-so"></div>
      </div>
      <!-- 联名相关 -->
      <div class="aboutel">
        <el-row class="foter-bos">
          <el-col :span="4">
            <div class="grid-content bg-purple">友情链接</div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple-light">吉星海软件</div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">衣科航</div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple-light">四川农业大学</div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple-light">四川畜牧局</div>
          </el-col>
        </el-row>
      </div>
      <!-- 署名 -->
      <div class="foter-record">
        <div class="record-number">
          <p class="text-p">Copyright Reserved 四川吉星海软件技术有限公司 2016-2019 蜀ICP备17005939号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerNav",
  data() {
    return {};
  }
};
</script>
<style>
</style>

<style lang="less">
.foters {
  display: flex;
  justify-content: center;
  .foter-up {
    width: 100%;
    height: 300px;
    background-color: #252b3a;
    .foter-foo {
      .link {
        border-bottom: 1px solid #9095a0;
        width: 1400px;
        margin-left: 230px;
        // 介绍
        .grid-content {
          .grid {
            padding-top: 30px;
            p {
              color: #9095a0;
              font-size: 12px;
              padding-top: 5px;
            }
            .el-col {
              width: 20%;
            }
          }
          span {
            color: #ffff;
            font-size: 18px;
          }
        }
        // 电话号码
        .tel-box {
          padding-top: 30px;
          .tel {
            border-left: 1px solid #9095a0;
            .text-p {
              color: #9095a0;

              font-size: 12px;
              padding-top: 5px;
            }
          }
        }
        //联名
      }
    }
    //联名
    .aboutel {
      padding-left: 224px;
      .foter-bos {
        border-bottom: 1px solid #9095a0;
        width: 1410px;
        .grid-content {
          color: #9095a0;
          font-size: 12px;
          margin: 20px 0 20px 0;
        }
      }
    }
    //署名
    .foter-record {
      .record-number {
        display: flex;
        justify-content: center;
        padding-top: 30px;
        .text-p {
          color: #9095a0;
          font-size: 14px;
        }
      }
    }
  }
}

// .el-row {
//   margin-bottom: 20px;
//   background-color: #252b3a;

//   width: 100%;
//   &:last-child {
//     margin-bottom: 0;
//   }
//   .foter-foo {
//     width: 1700px;

//     .link {
//       border-bottom: #9095a0 1px solid;
//       .link-het {
//         height: 150px;

//         .grid {
//           width: 100%;
//           height: 148px;
//           .el-col-6 {
//             width: 20%;
//           }
//           div {
//             margin-top: 20px;
//           }
//           .grid-content {
//             color: #fff;
//             margin: 0 0 20px;
//           }
//           p {
//             font-size: 10px;
//             color: #9095a0;
//           }
//         }
//         .tel-box {
//           height: 100%;
//           .tel {
//             border-left: #9095a0 1px solid;
//             position: absolute;
//             right: 40px;
//             top: 26px;
//             .text-p {
//               font-size: 10px;
//               color: #9095a0;
//               margin-top: 14px;
//               margin-left: 20px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .aboutel {
//     width: 1700px;
//     height: 100px;
//     border-bottom: solid 1px #9095a9;
//     position: absolute;
//     right: 100px;
//     top: 190px;
//     .foter-bos {
//       height: 25%;
//       position: absolute;
//       right: 0px;
//       top: 64px;
//       font-size: 10px;
//       color: #fff;
//       .el-col-4 {
//         width: 10%;
//       }
//     }
//   }
//   .foter-box {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: nowrap;
//   }
//   .record {
//     padding-right: 30%;
//     width: 100%;
//     .record-number {
//       width: 70%;
//       text-align: center;
//       margin: 0 auto;

//       margin-right: 200px;
//       .text-p {
//         color: #fff;
//       }
//     }
//   }
// }
</style>
