<template>
  <div class="index">
    <com-Header class="com-header"></com-Header>

    <div class="header-img">
      <div class="demo-image__lazy">
        <!-- <img src="../assets/imgs/ps98beeaf68b2fb6c9-7287-4cbf-b832-3cc6bfa51bc9_02.jpg" alt /> -->
        <div class="md-casd">
          <div class="md-card-media">
            <!-- swiper -->
            <swiper class="swiper-box" :options="swiperOption">
              <!-- 循环 -->
              <swiper-slide v-for="(item,index) in list" :key="index">
                <img class="imass" :src="item.img" />
                <div class="banner-title">
                  <h2>
                    星海智控
                    <br />告别粗放养殖实现智慧养殖
                  </h2>
                  <p>提供多终端智能硬件、AI大数据技术让车辆管理更加科学、智能实现节约成本、减少劳动力、增加营收</p>
                </div>
              </swiper-slide>

              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航 -->
    <div class="tages">
      <div class="navigation">
        <div class="tab-hd">
          <a href="javascript:;" :class="{'now':index==1}" @click="index=1">
            <img v-show="index!=1" src="../assets/imgs/车辆实时监控_nor@2x.png" alt />
            <img v-show="index==1" src="../assets/imgs/Monitor.png" alt />
            <p v-show="index!=1" style="color:#fff;">车辆实时监控</p>
            <p v-show="index==1" style="color:#ff7200;">车辆实时监控</p>
          </a>
          <a href="javascript:;" :class="{'now':index==2}" @click="index=2">
            <img v-show="index!=2" src="../assets/imgs/realtime.png" alt />
            <img v-show="index==2" src="../assets/imgs/genzob.png" alt />
            <p v-show="index!=2" style="color:#fff;">车辆实时跟踪</p>
            <p v-show="index==2" style="color:#ff7200;">车辆实时跟踪</p>
          </a>
          <a href="javascript:;" :class="{'now':index==3}" @click="index=3">
            <img v-show="index!=3" src="../assets/imgs/Monitorss.png" alt />
            <img v-show="index==3" src="../assets/imgs/equipment.png" alt />
            <p v-show="index!=3" style="color:#fff;">车载设备监测</p>
            <p v-show="index==3" style="color:#ff7200;">车载设备监测</p>
          </a>
          <a href="javascript:;" :class="{'now':index==4}" @click="index=4">
            <img v-show="index!=4" src="../assets/imgs/state.png" alt />
            <img v-show="index==4" src="../assets/imgs/statest.png" alt />
            <p v-show="index!=4" style="color:#fff;">车辆状态监测</p>
            <p v-show="index==4" style="color:#ff7200;">车辆状态监测</p>
          </a>
          <a href="javascript:;" :class="{'now':index==5}" @click="index=5">
            <img v-show="index!=5" src="../assets/imgs/runningstate.png" alt />
            <img v-show="index==5" src="../assets/imgs/running-state.png" alt />
            <p v-show="index!=5" style="color:#fff;">驾驶行为监测</p>
            <p v-show="index==5" style="color:#ff7200;">驾驶行为监测</p>
          </a>
          <a href="javascript:;" :class="{'now':index==6}" @click="index=6">
            <img v-show="index!=6" src="../assets/imgs/costst.png" alt />
            <img v-show="index==6" src="../assets/imgs/cost.png" alt />
            <p v-show="index!=6" style="color:#fff;">运行成本统计</p>
            <p v-show="index==6" style="color:#ff7200;">运行成本统计</p>
          </a>
          <a href="javascript:;" :class="{'now':index==7}" @click="index=7">
            <img v-show="index!=7" src="../assets/imgs/asscard.png" alt />
            <img v-show="index==7" src="../assets/imgs/expiration.png" alt />
            <p v-show="index!=7" style="color:#fff;">证件到期提醒</p>
            <p v-show="index==7" style="color:#ff7200;">证件到期提醒</p>
          </a>
        </div>

        <div class="tab-bd">
          <div class="tab-item" v-show="index==1">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>实时监控车辆分布</h2>
                  <p>平台集合了畜牧基本信息管理、视频监管、数量监管、健康监管、异常监管、数据分析等功能</p>
                  <ul>
                    <li>GPS+北斗双重定位，精确获取车辆位置</li>
                    <li>地图直观显示所有车辆位置，可直接点击查看具体车辆信息</li>
                    <li>输入车牌搜索车辆</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="navtg-b">
              <div class="text">
                <div class="text-box">
                  <h2>实时运行动态</h2>
                  <p>滚动最新车辆动态事件，点火、超速、停留、设备下线等各类事件一条不漏</p>
                  <ul>
                    <li>按事件紧急程度以不同样式播报，让重要信息更加瞩目</li>
                    <li>地图直观显示所有车辆位置，可直接点击查看具体车辆信息</li>
                    <li>输入车牌搜索车辆</li>
                  </ul>
                </div>
              </div>
              <div class="imassg">
                <img src="../assets/imgs/excssl.png" alt />
              </div>
            </div>
            <div class="navtg-c">
              <div class="imassg">
                <img src="../assets/imgs/fence.png" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>超出电子围栏实时通知</h2>
                  <p>平台集合了畜牧基本信息管理、视频监管、数量监管、健康监管、异常监管、数据分析等功能</p>
                  <ul>
                    <li>设备电子围栏，监控车辆使用范围</li>
                    <li>自定义配置监控时间段，无论超出或进入电子围栏实时通知后台</li>
                    <li>暂无</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==2">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>超低延时跟踪车辆行驶情况</h2>
                  <ul>
                    <li>跟踪车辆时，每三秒上报一次车辆行驶情况，实时动态跟踪车辆当前位置，展示车辆行驶轨迹</li>
                    <li>展示车辆行驶轨迹</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="navtg-b">
              <div class="text">
                <div class="text-box">
                  <h2>真实模拟行车状态</h2>
                  <ul>
                    <li>将实时数据通过车辆仪表的方式，直观的展示出来</li>
                    <li>结合车辆实时位置，轻松跟踪车辆</li>
                  </ul>
                </div>
              </div>
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
            </div>
            <div class="navtg-c">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>发动机转速，车速，水温等实时同步</h2>

                  <ul>
                    <li>监控行车电脑数据，发动机转速，车速，水温等实时同步查看</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==3">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>车载设备插拔行为实时上报</h2>
               
                   <ul>
                    <li>实时上报车载设备拔出，插入行为，杜绝人为破坏</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==4">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>运行状态监测</h2>

                  <ul>
                    <li>采用不同样式分别显示熄火停留，运行中，发生告警的车辆状况</li>
                    <li>以高亮闪烁方式突出显示发生告警信息的车辆</li>
                    <li>车辆熄火后依然处于监控中，以灰色图标占示车辆停放位置</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="navtg-b">
              <div class="text">
                <div class="text-box">
                  <h2>车辆故障检测</h2>

                  <ul>
                    <li>实时上报车辆各类状况</li>
                    <li>自动统计各类故障发生频次，为有效预防提供参考</li>
                    <li>输入车牌搜索车辆</li>
                  </ul>
                </div>
              </div>
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==5">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>驾驶行为检测</h2>

                  <ul>
                    <li>实时上报超速，急加速，急拐弯，长时间怠速停留，疲劳驾驶等各类不良行为</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==6">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>运行成本月度报表</h2>
                  <ul>
                    <li>从耗油，行驶里程，维保支出，中报费用等多维角度，自动生成车辆月度报表</li>
                    <li>各类费用统计自动完成，杜绝虚假上报</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="navtg-b">
              <div class="text">
                <div class="text-box">
                  <h2>油耗分析统计</h2>
                  <ul>
                    <li>管理油耗计划，智能对比油耗用量</li>
                    <li>统计分析车辆油耗及费用等情况，自动生成报表</li>
                  </ul>
                </div>
              </div>
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
            </div>
            <div class="navtg-c">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>运行维保费用统计</h2>
                  <ul>
                    <li>自动生成车辆维修，保养，年审，保险等各类维保产生的费用报表</li>
                    <li>申报统计差旅费，过路费等各种用车统计</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==7">
            <div class="navtg-a">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>保险到期提醒</h2>
                  <ul>
                    <li>保险到期前，自动提醒，并可生成到期保险报表</li>
                    <li>统计保险的费用，预估未来保险支出</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="navtg-b">
              <div class="text">
                <div class="text-box">
                  <h2>年审到期提醒</h2>
                  <ul>
                    <li>年审到期自动提醒</li>
                    <li>实时保存每次的年审记录，方便档案查询，储存和导出</li>
                  </ul>
                </div>
              </div>
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
            </div>
            <div class="navtg-c">
              <div class="imassg">
                <img src="../assets/imgs/4e3ba14098c2772632c8a40bf9f689e.jpg" alt />
              </div>
              <div class="text">
                <div class="text-box">
                  <h2>保养提醒</h2>

                  <ul>
                    <li>通过车辆行驶里程，行驶时间，科学计算保养时间，到期自动提醒车辆保养</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <com-footer></com-footer>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import ComFooter from "../components/Footer";
import ComHeader from "../components/Header";

export default {
  name: "index",
  components: {
    ComFooter,
    ComHeader
  },
  data() {
    return {
      index: 1,
      activeIndex2: "1",
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      list: [
        {
          img: require("../assets/imgs/banner1.jpg")
        },
        {
          img: require("../assets/imgs/banner1.jpg")
        }
      ]
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>
<style>
.banner-title {
  position: absolute;
  top: 300px;
  left: 200px;
  color: #fff;
}
.banner-title h2 {
  font-size: 60px;
  margin-bottom: 30px;
}
.swiper-pagination-bullet-active {
  width: 30px !important;
  background: #ff7200 !important;
  border-radius: 5px !important;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 0px;
}
</style>
<style lang="less">
.index {
  .swiper-slide {
    .imass {
      margin-bottom: -4px;
    }
  }
  color: #b3b3b3;
  .com-header {
    width: 100%;

    .demo-image__lazy {
      width: 100%;

      .swiper-box {
        width: 100%;
        height: 550px;
        .imass {
          width: 2000px;
        }
      }
    }
  }
  .tages {
    background-image: url("../assets/imgs/beijing.jpg");

    .navigation {
      height: 100%;
      .tab-hd {
        position: relative;
        height: 130px;
        padding-left: 220px;
        background-color: #252b3a;
        .tab-item {
          &.active {
            color: #ff7200;
          }
        }
      }
      .tab-hd a {
        float: left;
        padding: 8px 60px;
        margin-right: -1px;
        text-decoration: none;
        p {
          margin-left: -4px;
        }
      }
      .tab-hd a:hover {
        background-image: url("../assets/imgs/bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .tab-hd .now {
        padding: 8px 60px;
        background-image: url("../assets/imgs/bg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 2;
      }
      //动画
      .tab-bd {
        div {
          padding-bottom: 50px;

          position: relative;
          animation: mymove 2s infinite;
          -webkit-animation: mymove 2s infinite; /*Safari and Chrome*/
          animation-iteration-count: 1; /*执行一次的代码*/
          @keyframes mymove {
            from {
              left: 150px;
            }
            to {
              left: 0px;
            }
          }

          @-webkit-keyframes mymove /*Safari and Chrome*/ {
            from {
              left: 0px;
            }
            to {
              left: 0px;
            }
          }
        }
      }
      .navtg {
        height: 150px;
        width: 100%;
        background-color: #252b3a;

        ul {
          padding: 25px;
          display: flex;
          justify-content: center;
          list-style-type: none;
          li {
            margin: 0 87px 0 50px;
          }
        }
      }

      .navtg-c {
        display: flex;
        justify-content: center;
        margin: 0;
        .text {
          margin-left: 89px;
          .text-box {
            margin-bottom: 20px;

            color: #fff;

            h2 {
              color: #fff;
              margin: 20px;
            }
            p {
              color: #a8a8a8;
              margin: 20px;
            }
            ul {
              color: #fff;
              font-size: 18px;
              li {
                margin: 30px;
              }
            }
          }
        }
        .imassg {
          img {
            height: 360px;
            width: 500px;
            margin-right: 89px;
          }
        }
      }
      .navtg-d {
        display: flex;
        justify-content: center;
        margin: 0px;
        .text {
          margin-right: 89px;
        }
        .text-box {
          margin: 0;
          color: #fff;

          h2 {
            color: #fff;
            margin: 20px;
          }
          p {
            color: #a8a8a8;
            margin: 20px;
          }
          ul {
            color: #fff;
            font-size: 18px;
            li {
              margin: 30px;
            }
          }
        }
      }
      .imassg {
        img {
          height: 360px;
          width: 500px;
          margin-left: 89px;
        }
      }
      .navtg-a {
        display: flex;
        justify-content: center;
        margin: 220px 0 0 0;
        .text {
          margin-left: 89px;
          .text-box {
            margin: 0;
            color: #fff;

            h2 {
              color: #fff;
              margin: 20px;
            }
            p {
              color: #a8a8a8;
              margin: 20px;
            }
            ul {
              color: #fff;
              font-size: 18px;
              li {
                margin: 30px;
              }
            }
          }
        }
        .imassg {
          img {
            height: 360px;
            margin-right: 89px;
            width: 500px;
          }
        }
      }
      .navtg-b {
        display: flex;
        justify-content: center;
        margin: 0;
        .text {
          .text-box {
            margin: 0;
            color: #fff;

            h2 {
              color: #fff;
              margin: 20px;
            }
            p {
              color: #a8a8a8;
              margin: 20px;
            }
            ul {
              color: #fff;
              font-size: 18px;
              li {
                margin: 30px;
              }
            }
          }
        }
        .imassg {
          img {
            height: 360px;
            width: 500px;
          }
        }
      }
    }
    width: 100%;
  }
}
</style>