<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
          <!-- 导航 -->
          <div class="container">
            <!-- LOGO -->
            <div class="left">
              <div class="logos">
                <img src="../assets/imgs/logo (1).png" alt />
              </div>
             
            </div>
            <div class="pages">
              <div>
                <router-link to="/" :class="{'active':routerName=='index'}" style="color:#fff;text-decoration: none;">首页</router-link>
              </div>
              <div>
                <router-link to="/casees" :class="{'active':routerName=='casees'}" style="color:#fff;text-decoration: none;">案例</router-link>
              </div>
              <div>
                <router-link to="/experience" :class="{'active':routerName=='experience'}" style="color:#fff;text-decoration: none">体验试用</router-link>
              </div>
              <div>
                <router-link to="/about" :class="{'active':routerName=='about'}" style="color:#fff;text-decoration: none">关于我们</router-link>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "headerNav",
  data() {
    return {
      routerName: this.$route.name
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  }
};
</script>
<style>
/* .swiper-pagination-bullet-active{
   width: 30px!important;
   background: #ff7200!important;
   border-radius: 5px!important
 }

 .swiper-pagination-bullet{
   width: 12px;
   height: 12px;
   border-radius: 0px
 } */
</style>
<style lang="less" scoped>
.grid-content {
  .active {
    color: #ff7200 !important;
  }
  .router-link-active:active {
    color: #fff !important;
    text-decoration: none !important;
  }
  width: 100%;
  
  background-color: #252b3a;
  .container {
    width: 1400px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
    margin-left: 0;
  }
  .logos {
   
    img {
      width: 100%;
    }
  }
  .text-xin {
    font-size: 18px;
    margin-left: 12px;
    color: #fff;
  }
}

.pages {
  display: flex;
  color: #ffffff;
  div {
    margin-left: 20px;
    color: #ffffff;
    font-size: 14px;
    .router-link {
      &.active {
        color: #ff7200;
      }
    }
  }

  .header-top {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    .shouye {
      -color: #ff7200;
    }
    div {
      margin-right: 20px;
      color: #ffffff;
      font-size: 12px;
    }
  }
}
.demo-image__lazy {
  width: 100%;

  img {
    width: 100%;
  }
}
</style>
