import Vue from 'vue'
import Router from 'vue-router'
import index from './views/index'
import aboutUs from './views/aboutUs'
import experience from './views/experience'
import casees from './views/casees'
// import axios from './views/axios'
Vue.use(Router)
// Vue.use(axios)
export default new Router({

    //配置激活时要使用的类名	
    linkActiveClass: 'router-link-active',

    waitForData: true,
    transitionOnLoad: true,


    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/casees',//首页
            name: 'casees',
            component: casees
        },
        {
            path: '/',
            name: 'index',
            component: index
        },
        {
            path: '/about',
            name: 'about',
            component: aboutUs
        },
        {
            path: '/experience',
            name: 'experience',
            component: experience

        },

    ]
})