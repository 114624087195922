import axios from "axios";
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
axios.defaults.headers.get["Content-Type"] =
    "application/x-www-form-urlencoded";
axios.defaults.transformRequest = [
    function (data) {
        let ret = "";
        for (let it in data) {
            ret += encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        ret = ret.substring(0, ret.length - 1);
        console.log("ret", ret);
        return ret;
    }
];
console.log('process.env ===', process.env.NODE_ENV == 'development');
const api = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'api1/' : 'http://demo.jixinghai.com'
})
export default api