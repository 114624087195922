import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';


import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'

import 'swiper/dist/css/swiper.css'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'Rf1Tcig6DtNUeKynuLzVMfepFe1nZX8S'
})
Vue.use(VueAwesomeSwiper, /* { default global options } */)

Vue.use(ElementUI);



Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
