<template>
  <div>
    <ComHeader></ComHeader>
    <div class="banners">
      <img src="../assets/imgs/anli.jpg" alt />
    </div>
    <div class="navtion">
      <div class="aboution">
        <div class="case-box">
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="imags">
            <ul>
              <li>
                <div>
                  <img src="../assets/imgs/che.jpg" alt />
                </div>
              </li>
              <li>
                <img src="../assets/imgs/fenge.jpg" alt />
              </li>
              <li>
                <div>
                  <span>19.2</span>
                  <p>明星电力车队</p>
                  <p>基本杜绝因班车造成的员工迟到问题，优化路线，提高班车利用率，规范了员工乘车情况</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import ComFooter from "../components/Footer";
import ComHeader from "../components/Header";
export default {
  name: "casees",
  components: {
    ComFooter,
    ComHeader
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.banners {
  margin-bottom: -4px !important;
}
.navtion {
  background-color: #181d29;
  width: 100%;
  height: 100%;
  .aboution {
    .case-box{
      padding-bottom: 50px;
    }
    width: 1267px;
    height: 100%;
    background-color: #fff;
    margin-left: 326px;
    .imags {
      margin-left: 170px;
      ul {
        padding-top: 50px;
        display: flex;
        list-style-type: none;
        justify-content: flex-start;
        li {
          padding-left: 30px;
        }
      }
    }
  }
}
</style>
