<template>
  <div>
    <ComHeader></ComHeader>
    <div class="banners" style="margin-bottom: -4px;">
      <img src="../assets/imgs/bg11.png" style=" margin-bottom: -4px" alt />
    </div>
    <div class="navtion">
      <div class="tabs">
        <div class="tab-hd">
          <a href="javascript:;" :class="{'now':index==1}" @click="index=1">
            <p>公司介绍</p>
          </a>
          <a href="javascript:;" :class="{'now':index==2}" @click="index=2">
            <p>团队介绍</p>
          </a>
          <a href="javascript:;" :class="{'now':index==3}" @click="index=3">
            <p>资质荣誉</p>
          </a>
          <a href="javascript:;" :class="{'now':index==4}" @click="index=4">
            <p>联系我们</p>
          </a>
        </div>
        <div class="tab-bd">
          <div class="tab-item" v-show="index==1">
            <div class="items">
              <div class="text">
                <p>
                  <span style="color:#ff7200">四川吉星海软件技术有限公司</span>成立于2016年，是一家具有丰富技术经验，富有创新精神的互联网科技企业。团队核心成员均来自国内一线互联网企业，在技术、架构、产品等方面具有丰富经验。
                </p>
              </div>
              <div class="text">
                <p>公司成立之初定位于为企业提供“物联网+企业信息化+大数据+人工智能” 技术支持服务。通过物联网技术，为企业解决信息化，智能化建设，通过大数据分析和人工智能，为企业带来更加高效的智能化管理。</p>
              </div>
              <div class="text">
                <p>公司在2017年8月在天府（四川）联合股权交易中心完成创企业挂牌，证券代码811541，目前与国内世界500强，上市公司、物联网技术等知名企业以及各大高校达成了战略合作协议关系。</p>
              </div>
              <div class="text">
                <p>
                  公司目前已有成熟的
                  <span style="color:#ff7200">“智慧禽牧”</span>和
                  <span style="color:#ff7200">“智慧化车队管理”</span>两大产品，且成功申请并授权多项技术发明专利和软件著作权，项目分别在CCTV-7和广西新闻频道上进行报道。公司目前为华为云解决方案合作伙伴及认证经销商，中移物联网认证合作伙伴（OCP）、四川
                  农业大学产学研合作单位共同组建智慧畜牧研究中心、与四川省通信产业服务有限公司建立合作伙伴关系，同时公司也获得了四川省信息产业发展研究中心的大力支持。
                </p>
              </div>
              <div class="text">
                <p>目前公司正在申请国家高新技术企业。</p>
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==2">
            <div class="teams">
              <div class="textHeader">
                <p>来自大型硬件公司、BAT、思科等大型科研和市场人员，所有人员均有多年从业经验和丰富的实战经验；我们的伙伴除了自身专业技能外，大部分都拥有几项技能，完全十足的全能型复合人才。</p>
              </div>
              <div class="personnel">
                <div class="Founder">
                  <span class="person">张俤</span>
                  <span class="Title">总经理&技术总监</span>
                  <span
                    class="line"
                  >---------------------------------------------------------------------------------</span>
                </div>
                <div class="contens">
                  <ul>
                    <li>公司创始人，10年+互联网经验</li>
                    <li>历任纳斯达克上市公司泛华普益财富技术经理、惠装网技术主管、VISTA看天下全资子公司技术总监、董事</li>
                    <li>10年+互联网行业技术管理经验，拥有技术发明专利已授权6项，在国家级杂志发表研究论文一篇</li>
                    <li>负责公司战略、经营管理及技术管理</li>
                  </ul>
                </div>
              </div>
              <div class="personnel">
                <div class="Founder">
                  <span class="person">李榜东</span>
                  <span class="Title">产品经理</span>
                  <span
                    class="line"
                  >---------------------------------------------------------------------------------</span>
                </div>
                <div class="contens">
                  <ul>
                    <li>9年互联网从业经验，7年互联网产品设计及管理经验</li>
                    <li>曾任腾讯电脑管家运营管理；由QQ宝箱转型为电脑管家，经过团队半年运营，市场占额突破25%，电脑管家MAU达到200万+，动态拦</li>
                    <li>曾任小蜜蜂科技运营和企业管理，负责企业发展战略规划和智慧有机肉类溯源平台产品设计及运营管理</li>
                    <li>负责公司智慧畜牧项目产品及运营管理</li>
                  </ul>
                </div>
              </div>
              <div class="personnel">
                <div class="Founder">
                  <span class="person">陈仕毅</span>
                  <span class="Title">博士，讲师，硕士生导师，AI算法研究及指导</span>
                  <span
                    class="line"
                  >---------------------------------------------------------------------------------</span>
                </div>
                <div class="contens">
                  <ul>
                    <li>2009年毕业于四川农业大学动物遗传育种与繁殖专业，获农学博士学位</li>
                    <li>2010至今，在四川农业大学，动物遗传育种研究所，草食动物研究室任教。先后以第一作者或通讯作者共发表科研论文20篇，其中SCI2010至今，在四川农业大学，动物遗传育种研究所，草食动物研究室任教。先后以第一作者或通讯作者共发表科研论文20篇，其中SCI</li>
                    <li>主要从事牛遗传育种与繁殖领域的科研、教学工作</li>
                    <li>负责智慧畜牧项目AI算法研究及指导</li>
                  </ul>
                </div>
              </div>
              <div class="personnel">
                <div class="Founder">
                  <span class="person">贾先波</span>
                  <span class="Title">博士，讲师，硕士生导师，繁殖研究及指导</span>
                  <span
                    class="line"
                  >---------------------------------------------------------------------------------</span>
                </div>
                <div class="contens">
                  <ul>
                    <li>2013年毕业于中国农业大学动物遗传育种与繁殖专业，获农学博士学位</li>
                    <li>2013至今，在四川农业大学，动物遗传育种研究所，草食动物研究室任教。主持四川省教育厅基金项目1项，主研“国家科技支撑计划”、2013至今，在四川农业大学，动物遗传育种研究所，草食动物研究室任教。主持四川省教育厅基金项目1项，主研“国家科技支撑计划”、</li>
                    <li>负责智慧畜牧项目繁殖研究及指导</li>
                  </ul>
                </div>
              </div>
             
            </div>
          </div>
          <div class="tab-item" v-show="index==3">
            <div class="rongyu-box">
              <div class="rongyu">
                <img src="../assets/imgs/rongyu1.jpg" alt />
              </div>
              <div class="rongyu">
                <img src="../assets/imgs/rongyu2.jpg" alt />
              </div>
              <div class="rongyu">
                <img src="../assets/imgs/rongyu3.jpg" alt />
              </div>
            </div>
          </div>
          <div class="tab-item" v-show="index==4">
            <div class="tel-about">
              <div class="maps">
                <baidu-map
                  :center="{ lng: 104.068167, lat: 30.578601 }"
                  :zoom="16"
                  @ready="handler"
                  style="height:238px"
                  @click="getClickInfo"
                  :scroll-wheel-zoom="true"
                ></baidu-map>
              </div>
              <div class="phoneus">
                <img src="../assets/imgs/telphone.jpg" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ComFooter></ComFooter>
  </div>
</template>

<script>
import ComFooter from "../components/Footer";
import ComHeader from "../components/Header";
export default {
  name: "about",
  components: {
    ComFooter,
    ComHeader
  },
  data() {
    return {
      activeName2: "frist",
      index: 1
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handler({ BMap, map }) {
      var point = new BMap.Point(104.068167, 30.577601);
      // map.centerAndZoom(point, 16);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      // var circle = new BMap.Circle(point, 6, {
      //   strokeColor: "Red",
      //   strokeWeight: 6,
      //   strokeOpacity: 1,
      //   Color: "Red",
      //   fillColor: "#f03"
      // });
      // map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    }
  }
};
</script>
<style>

</style>
<style lang="less" scoped>
.banners {
  margin-bottom: -4px !important;
  img{
    height: 540px;
    width: 100%;
  }
}
.navtion {
  background-color: #181d29;
  width: 100%;
  height: 100%;

  .aboution {
    width: 1267px;
    height: 450px;

    margin-left: 326px;
    .text {
      width: 1080px;
      padding-left: 80px;
      word-break: break-all;
      p {
        span {
          color: #ff7200;
        }
      }
    }
  }
}
.tab-hd {
  position: relative;
  height: 36px;

  width: 100%;
}
.tab-hd a {
  p {
    padding: 20px;
  }
  div {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    padding: 20px;
  }
  width: 277px;
  height: 64px;
  float: left;
  padding: 8px 20px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  background: #252b3a;
}
.tab-hd a:hover {
  background: #ff7200;
}
.tab-hd .now {
  padding: 8px 20px 7px;
  background: #252b3a;
  z-index: 2;
}
.tab-bd {
  .tab-item {
    .items {
      width: 1050px;
      margin: 50px 120px 0;
    }

    .text {
      padding: 15px;
      p {
        margin-top: 5px;
        line-height: 35px;
        text-indent: 2em;
      }
    }
    .teams {
      padding: 70px;
      .textHeader {
        p {
          font-size: 14px;
          color: #252b3a;
          padding: 25px;
        }
      }
      .personnel {
        .Founder {
          padding-top: 10px;
          .person {
            font-size: 28px;
            margin-right: 10px;
          }
          .Title {
            color: #ff7200;
            margin-right: 10px;
          }
          .line {
            color: #e6e6e6;
            margin-right: 10px;
          }
        }
        .contens {
          ul {
            li {
              margin: 17px;
            }
          }
        }
      }
    }
    .rongyu-box {
      margin-top: 40px;
      .rongyu {
        padding: 38px 60px 30px;
      }
    }
    .tel-about {
      margin-left: 102px;
      width: 1050px;
      height: 600px;
      .maps {
        padding: 90px 0 30px;
      }
      .phoneus {
        padding: 25px;
        img {
          margin: -92px;
        }
      }
    }
  }
}
.tabs {
  background-color: #fff;
  width: 1270px;

  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  top: -84px;
  opacity: 0.9;

  /deep/ .el-tabs__nav-wrap {
    padding: 0;
  }
  /deep/ .el-tabs__item {
    width: 318px;
    height: 70px;
    text-align: center;
    line-height: 65px;
    font-size: 18px;
    color: #fff;
    background-color: #181d29;
  }
  /deep/ .el-tabs__item .is-active:active {
    width: 317px;
    height: 70px;

    background-color: #ff7200;
  }
  /deep/ .el-tabs__item .is-active {
    background-color: #ff7200;
  }
}
</style>